<template>
  <div>
    <b-modal
      @hidden="resetModal"
      ref="modal-trigger-callback"
      centered
      hide-footer
      size="md"
      :no-close-on-backdrop="true"
      content-class="overflow-hidden"
    >
    <template #modal-title>
      <h5 class="text-info mb-0 text-dark"><i class="uil uil-exchange-alt text-primary"></i> Trigger Callback</h5>
    </template>
      <div class="row align-items-center">
        <div class="col-12" >
          <div class="mb-3 mb-lg-2 row" v-if="updateData.merchant_info">
            <label class="col-lg-4 col-12 col-form-label py-0">Merchant:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div v-if="updateData.merchant_info">#{{ updateData.merchant_info.merchant_id }} {{ updateData.merchant_info.merchant_name }}</div>
            </div>
          </div>
          <div class="mb-3 mb-lg-2 row" v-if="updateData.merchant_name">
            <label class="col-lg-4 col-12 col-form-label py-0">Merchant:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div> #{{ updateData.merchant_id }} {{ updateData.merchant_name }}</div>
            </div>
          </div>
          <div class="mb-3 mb-lg-2 row" v-if="updateData.deposit_id">
            <label class="col-lg-4 col-12 col-form-label py-0">Deposit ID:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div> {{ updateData.deposit_id }}</div>
            </div>
          </div>

          <div class="mb-3 mb-lg-2 row" v-if="updateData.payout_id">
            <label class="col-lg-4 col-12 col-form-label py-0">Payout ID:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div> {{ updateData.payout_id }}</div>
            </div>
          </div>

          <div class="mb-3 mb-lg-2 row" v-if="updateData.settlement_id">
            <label class="col-lg-4 col-12 col-form-label py-0">Settlement ID:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div> {{ updateData.settlement_id }}</div>
            </div>
          </div>

          <div class="mb-3 mb-lg-2 row" v-if="updateData.reference_no">
            <label class="col-lg-4 col-12 col-form-label py-0">Reference No.:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div> {{ updateData.reference_no }}</div>
            </div>
          </div>

          <div class="mb-3 mb-lg-2 row">
            <label class="col-lg-4 col-12 col-form-label py-0">Transaction ID/UTR:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div> {{ updateData.transaction_id || '-' }}</div>
            </div>
          </div>

          <div class="mb-3 mb-lg-2 row">
            <label class="col-lg-4 col-12 col-form-label py-0">Status:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div>
                <span class="badge bg-soft-success rounded-pill font-size-14" v-if="updateData.status =='success'">Successful</span> 
                <span class="badge bg-soft-purple rounded-pill font-size-14" v-else-if="updateData.status =='pending'">Processing</span>
                <span class="badge bg-soft-danger rounded-pill font-size-14" v-else-if="updateData.status =='failed'" v-b-tooltip.hover :title="updateData.reason">Failed</span>
                <span class="badge bg-soft-danger rounded-pill font-size-14" v-else-if="updateData.status =='rejected'" v-b-tooltip.hover :title="updateData.rejected_reason || updateData.reason">Rejected <i class="uil uil-question-circle"></i></span>
                <span class="badge bg-soft-success rounded-pill font-size-14" v-if="updateData.status =='complete'">Completed</span> 
                
              </div>
            </div>
          </div>

          <div class="mb-3 mb-lg-2 row" v-if="updateData.merchant_callback_url">
            <label class="col-lg-4 col-12 col-form-label py-0">Callback Url:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div> {{ updateData.merchant_callback_url || '-' }}</div>
            </div>
          </div>
          
          <hr>
          <div class="alert alert-info bg-info text-white border-info" role="alert">
            <p class="mb-1">The callback data are updated after triggering the callback. Please enter the password and click the 'Update' button.</p>
           <p class="mb-0">当触发回调后，回调数据会更新。 请输入密码并单击“更新”按钮。</p>
          </div>
          <div class="mb-3 col-12 col-md-12">
                 <label>Password</label>
                 <div class="position-relative">
                    <input
                       v-model="formData.password"
                       :type="passwordFieldType"
                       name="password"
                       class="form-control"
                       :class="{
                       'is-invalid':
                       modalSubmit && $v.formData.password.$error,
                       }"
                       placeholder="Password"
                       />
                    <div
                       v-if="modalSubmit && $v.formData.password.$error"
                       class="invalid-feedback"
                       >
                       <span v-if="!$v.formData.password.required"
                          >Password is required.</span
                          >
                    </div>
                    <div
                       id="eye"
                       class="user-select-none"
                       @click="switchVisibility()"
                       >
                       <i
                          :class="classicon"
                          id="eyetype"
                          aria-hidden="true"
                          ></i>
                    </div>
                 </div>
              </div>
        <hr>
         
        <div class="d-grid gap-2 d-md-block text-end">
          <button type="button" class="btn fw-medium btn-secondary me-md-2" @click="$refs['modal-trigger-callback'].hide()">Cancel</button>
          
          <button type="button" class="btn fw-medium btn-info" @click="updateCallback" :disabled="modalLoading">
            <span v-if="modalLoading"> Updating...</span>
            <span v-else> Update </span>
            <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
          </button>
        </div>
      </div>
        
      </div>
    </b-modal>
    <b-modal  
      @hidden="resetModal"
      ref="modal-trigger-success"
      centered
      hide-footer
      size="md"
      :no-close-on-backdrop="true"
      content-class="overflow-hidden"
    >
    <template #modal-title>
      <h5 class="text-info mb-0 text-dark"><i class="uil uil-exchange-alt text-primary"></i> Update Status and Trigger Callback</h5>
    </template>
      <div class="row align-items-center">
        <div class="col-12" >
          <div class="mb-3 mb-lg-2 row" v-if="updateData.merchant_info">
            <label class="col-lg-4 col-12 col-form-label py-0">Merchant:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div v-if="updateData.merchant_info">#{{ updateData.merchant_info.merchant_id }} {{ updateData.merchant_info.merchant_name }}</div>
            </div>
          </div>
          <div class="mb-3 mb-lg-2 row" v-if="updateData.merchant_name">
            <label class="col-lg-4 col-12 col-form-label py-0">Merchant:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div> #{{ updateData.merchant_id }} {{ updateData.merchant_name }}</div>
            </div>
          </div>

          <div class="mb-3 mb-lg-2 row" v-if="updateData.deposit_id">
            <label class="col-lg-4 col-12 col-form-label py-0">Deposit ID:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div> {{ updateData.deposit_id }}</div>
            </div>
          </div>

          <div class="mb-3 mb-lg-2 row" v-if="updateData.payout_id">
            <label class="col-lg-4 col-12 col-form-label py-0">Payout ID:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div> {{ updateData.payout_id }}</div>
            </div>
          </div>

          <div class="mb-3 mb-lg-2 row" v-if="updateData.settlement_id">
            <label class="col-lg-4 col-12 col-form-label py-0">Settlement ID:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div> {{ updateData.settlement_id }}</div>
            </div>
          </div>

          <div class="mb-3 mb-lg-2 row">
            <label class="col-lg-4 col-12 col-form-label py-0">Reference No.:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div> {{ updateData.reference_no }}</div>
            </div>
          </div>

          <div class="mb-3 mb-lg-2 row" v-if="fromAction=='payin'">
            <label class="col-lg-4 col-12 col-form-label py-0">Transaction ID/UTR:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div> {{ updateData.transaction_id || '-' }}</div>
            </div>
          </div>

          <div class="mb-3 mb-lg-2 row" v-else>
            <label class="col-lg-4 col-12 col-form-label py-0">Transaction ID:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div> {{ updateData.transaction_id || '-' }}</div>
            </div>
          </div>


          <div class="mb-3 mb-lg-2 row">
            <label class="col-lg-4 col-12 col-form-label py-0 mb-1">Status:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div>
                <span class="badge bg-soft-success rounded-pill font-size-14" v-if="updateData.status =='success'">Successful</span> 
                <span class="badge bg-soft-purple rounded-pill font-size-14" v-else-if="updateData.status =='pending'">Processing</span>
                <span class="badge bg-soft-danger rounded-pill font-size-14" v-else-if="updateData.status =='failed'" v-b-tooltip.hover :title="updateData.reason">Failed</span>
                <span class="badge bg-soft-danger rounded-pill font-size-14" v-else-if="updateData.status =='rejected'" v-b-tooltip.hover :title="updateData.reason">Rejected <i class="uil uil-question-circle"></i></span>
                <span class="badge bg-soft-success rounded-pill font-size-14" v-if="updateData.status =='complete'">Completed</span> 
              </div>
            </div>
          </div>
          
          <hr>
          <div class="alert alert-info bg-info text-white border-info" role="alert">
           <p class="mb-1">The callback data and status are updated after triggering the callback. Please enter the password and click the 'Update' button.</p>
           <p class="mb-0">当触发回调后，回调数据和状态会更新。 请输入密码并单击“更新”按钮。</p>
          </div>
          <div class="mb-3 col-12 col-md-12" v-if="fromAction=='payin' && (updateData.status =='failed' || updateData.status =='pending')">
            <label>Transaction ID/UTR</label>
            <div class="position-relative">
              <input
                type="text" inputmode="decimal" @input="formData.utrCode.length>0 ? formData.status='success' : formData.status='failed'"
                placeholder="Transaction ID/UTR"
                class="form-control"
                :disabled="disabled"
                v-model.trim="formData.utrCode"
              />

              <!-- <input
                type="text" inputmode="decimal" @input="inputNumberOnly($event, 'utrCode'), formData.utrCode.length>0 ? formData.status='success' : formData.status='failed'"
                placeholder="Transaction ID/UTR"
                class="form-control"
                :disabled="disabled"
                v-model.trim="formData.utrCode"
              /> -->
            </div>
          </div>
          <div class="mb-3 col-12 col-md-12" v-if="updateData.status =='pending' || updateData.status =='failed' ">
            <label>Status</label>
            <div class="position-relative">
            <select class="form-select" aria-label="" v-model="formData.status">
              <option value="failed">Failed</option>
              <option value="success">Success</option>
            </select>
            </div>
            <div v-if="formData.status=='failed'" class="mt-3">
              <label>Failed Reason</label>
              <textarea class="form-control text-body" v-model="formData.reason" rows="2"></textarea>
            </div>
          </div>
          
          <!-- <div class="mb-3 col-12 col-md-12" v-else>
            <label>Status1</label>
            <div class="position-relative">
            <select class="form-select" aria-label="" v-model="updateData.status" disabled>
              <option value="failed">Failed</option>
              <option value="success">Success</option>
            </select>
            </div>
          </div> -->
          <div class="mb-3 col-12 col-md-12">
            <label>Password</label>
            <div class="position-relative">
              <input
                  v-model="formData.password"
                  :type="passwordFieldType"
                  name="password"
                  class="form-control"
                  :class="{
                  'is-invalid':
                  modalSubmit && $v.formData.password.$error,
                  }"
                  placeholder="Password"
                  />
              <div
                  v-if="modalSubmit && $v.formData.password.$error"
                  class="invalid-feedback"
                  >
                  <span v-if="!$v.formData.password.required"
                    >Password is required.</span
                    >
              </div>
              <div
                  id="eye"
                  class="user-select-none"
                  @click="switchVisibility()"
                  >
                  <i
                    :class="classicon"
                    id="eyetype"
                    aria-hidden="true"
                    ></i>
              </div>
            </div>
          </div>
              
          
        <hr>
         
        <div class="d-grid gap-2 d-md-block text-end">
          <button type="button" class="btn fw-medium btn-secondary me-md-2" @click="$refs['modal-trigger-success'].hide()">Cancel</button>
          
          <button type="button" class="btn fw-medium btn-info" @click="updateCallback" :disabled="modalLoading">
            <span v-if="modalLoading"> Updating...</span>
            <span v-else> Update </span>
            <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
          </button>
        </div>
      </div>
        
      </div>
    </b-modal>
    <b-modal  
      @hidden="resetModal"
      ref="modal-refund"
      centered
      hide-footer
      size="md"
      :no-close-on-backdrop="true"
      content-class="overflow-hidden"
    >
    <template #modal-title>
      <h5 class="text-info mb-0 text-dark">
        <i class="bi bi-arrow-up-left-circle text-primary me-1 font-size-20"></i>
        Transaction Reversal (冲正)
      </h5>
    </template>
      <div class="row align-items-center">
        <div class="col-12" >
          <div class="mb-3 mb-lg-2 row" v-if="updateData.merchant_info">
            <label class="col-lg-4 col-12 col-form-label py-0">Merchant:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div v-if="updateData.merchant_info">#{{ updateData.merchant_info.merchant_id }} {{ updateData.merchant_info.merchant_name }}</div>
            </div>
          </div>
          <div class="mb-3 mb-lg-2 row" v-if="updateData.merchant_name">
            <label class="col-lg-4 col-12 col-form-label py-0">Merchant:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div> #{{ updateData.merchant_id }} {{ updateData.merchant_name }}</div>
            </div>
          </div>

          <div class="mb-3 mb-lg-2 row" v-if="updateData.deposit_id">
            <label class="col-lg-4 col-12 col-form-label py-0">Deposit ID:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div> {{ updateData.deposit_id }}</div>
            </div>
          </div>

          <div class="mb-3 mb-lg-2 row" v-if="updateData.payout_id">
            <label class="col-lg-4 col-12 col-form-label py-0">Payout ID:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div> {{ updateData.payout_id }}</div>
            </div>
          </div>

          <div class="mb-3 mb-lg-2 row" v-if="updateData.settlement_id">
            <label class="col-lg-4 col-12 col-form-label py-0">Settlement ID:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div> {{ updateData.settlement_id }}</div>
            </div>
          </div>

          <div class="mb-3 mb-lg-2 row">
            <label class="col-lg-4 col-12 col-form-label py-0">Reference No.:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div> {{ updateData.reference_no }}</div>
            </div>
          </div>

          <div class="mb-3 mb-lg-2 row">
            <label class="col-lg-4 col-12 col-form-label py-0">Transaction ID/UTR:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div> {{ updateData.transaction_id || '-' }}</div>
            </div>
          </div>

          <div class="mb-3 mb-lg-2 row">
            <label class="col-lg-4 col-12 col-form-label py-0 mb-1">Status:</label>
            <div class="col-lg-8 col-12 text-muted">
              <div>
                <span class="badge bg-soft-success rounded-pill font-size-14" v-if="updateData.status =='success'">Successful</span> 
                <span class="badge bg-soft-purple rounded-pill font-size-14" v-else-if="updateData.status =='pending'">Processing</span>
                <span class="badge bg-soft-danger rounded-pill font-size-14" v-else-if="updateData.status =='failed'" v-b-tooltip.hover :title="updateData.reason">Failed</span>
                <span class="badge bg-soft-danger rounded-pill font-size-14" v-else-if="updateData.status =='rejected'" v-b-tooltip.hover :title="updateData.reason">Rejected <i class="uil uil-question-circle"></i></span>
                <span class="badge bg-soft-success rounded-pill font-size-14" v-if="updateData.status =='complete'">Completed</span> 
              </div>
            </div>
          </div>
          
          <hr>
          <div class="mb-3 col-12 col-md-12" v-if="fromAction=='payin' && (updateData.status =='failed' || updateData.status =='pending')">
            <label>Transaction ID/UTR</label>
            <div class="position-relative">
              <input
                type="text" inputmode="decimal" 
                placeholder="Transaction ID/UTR"
                class="form-control"
                :disabled="disabled"
                v-model.trim="formData.utrCode"
              />
            </div>
          </div>
          <div class="mb-3 col-12 col-md-12" v-if="updateData.status =='success' || updateData.status =='complete'">
            <label>Status</label>
            <div class="position-relative">
            <select class="form-select" aria-label="" v-model="formData.status">
              <option value="reversed">Reversed</option>
            </select>
            </div>
            <div class="mt-3">
              <label>Remarks</label>
              <textarea class="form-control text-body" v-model="formData.remarks" rows="2"></textarea>
            </div>
          </div>
          
          <!-- <div class="mb-3 col-12 col-md-12" v-else>
            <label>Status1</label>
            <div class="position-relative">
            <select class="form-select" aria-label="" v-model="updateData.status" disabled>
              <option value="failed">Failed</option>
              <option value="success">Success</option>
            </select>
            </div>
          </div> -->
          <div class="mb-3 col-12 col-md-12">
            <label>Password</label>
            <div class="position-relative">
              <input
                  v-model="formData.password"
                  :type="passwordFieldType"
                  name="password"
                  class="form-control"
                  :class="{
                  'is-invalid':
                  modalSubmit && $v.formData.password.$error,
                  }"
                  placeholder="Password"
                  />
              <div
                  v-if="modalSubmit && $v.formData.password.$error"
                  class="invalid-feedback"
                  >
                  <span v-if="!$v.formData.password.required"
                    >Password is required.</span
                    >
              </div>
              <div
                  id="eye"
                  class="user-select-none"
                  @click="switchVisibility()"
                  >
                  <i
                    :class="classicon"
                    id="eyetype"
                    aria-hidden="true"
                    ></i>
              </div>
            </div>
          </div>
              
          
        <hr>
         
        <div class="d-grid gap-2 d-md-block text-end">
          <button type="button" class="btn fw-medium btn-secondary me-md-2" @click="$refs['modal-refund'].hide()">Cancel</button>
          
          <button type="button" class="btn fw-medium btn-info" @click="refund" :disabled="modalLoading">
            <span v-if="modalLoading"> Updating...</span>
            <span v-else> Update </span>
            <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
          </button>
        </div>
      </div>
        
      </div>
    </b-modal>
    <Common ref="commonFunc" />
  </div>
</template>
<script>

import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";
import Common from "@/components/common";
import { required} from "vuelidate/lib/validators";
export default {
  components: { 
    Common
  },
  props: {
      data: {
        type: Object,
        required: true,
      }
  },
  data() {
    return {
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      selectedBanks:[],
      banksList:[],
      loading:false,
      disabled:false,
      modalSubmit:false,
      modalLoading:false,
      passwordFieldType_current: "password",
      classicon_current: "uil uil-eye-slash",
      passwordFieldType: "password",
      classicon: "uil uil-eye-slash",
      passwordFieldType2: "password",
      classicon2: "uil uil-eye-slash",
      returnData: [],
      formData: {
        updateId: "",
        merchantId:"",
        password:"",
        status:"",
        reason:"",
        remarks:"",
        utrCode:"",
        platformTransactionId:"",
        transactionId:"",
      },
      updateData:{},
      fromAction: "",
    };
  },
  validations: {
    formData: {
      updateId:{
        required,
      },
      merchantId:{
        required,
      },
      password: {
        required
      },
    },
  },
  middleware: "authentication",
  async mounted(){
   
  //   this.title = PageTitle
  //   this.items[1].text = PageTitle
  //   // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
  //   // this.$refs.main.changeVerticalTopBar("bill",true)
  //   // this.$refs.main.setShowFooterCert(false)
  //   // this.$refs.main.setPageTitle('title')
  //   console.log( this.$refs.commonFunc.getPhone())
  //   this.accessToken = this.$refs.commonFunc.getToken()
  //   this.accessUsername = this.$refs.commonFunc.getUsername()
  //   await this.getData();
    
  }, 
  created(){
   
  },
  methods:{
    switchVisibility() {
      (this.classicon =
        this.classicon === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType =
          this.passwordFieldType === "password" ? "text" : "password");
    },
    inputNumberOnly(event, parentModel) {
      const numericValue = event.target.value.replace(/[^0-9.]/g, '');
      this.formData[parentModel] = numericValue;
    },
    convertCurrencyFormat(value,show00) {
      if (show00==true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value)
      }else{
         // Assuming this.transactionLimit is defined in your component's data
         return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
      }
    },
    showTriggerCallbackModal(value){
      this.$refs['modal-trigger-callback'].show()
      this.accessToken = this.data.accessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData;
      this.fromAction = value
      if (value=='payin'){
        this.formData.updateId = this.data.updateData.reference_no;
        this.formData.merchantId = this.data.updateData.merchant_info.merchant_db_code;
        this.formData.platformTransactionId = this.data.updateData.platform_transaction_id;
        if (this.data.updateData.status == 'success'){
          this.formData.utrCode = this.data.updateData.transaction_id
        }
      }else if (value=='payout'){
        this.formData.updateId = this.data.updateData.reference_no;
        this.formData.merchantId = this.data.updateData.merchant_info.merchant_db_code;
      }
      else if (value=='settlement'){
        this.formData.updateId = this.data.updateData.settlement_id;
        this.formData.merchantId = this.data.updateData.merchant_db_code;
      }
      this.formData.status = this.data.updateData.status;
      this.formData.transactionId = this.data.updateData.transaction_id;
      this.formData.reason = (this.data.updateData.reason)==null ? '' :this.data.updateData.reason ; 
    },

    showTrigger2successModal(value){
      this.$refs['modal-trigger-success'].show()
      this.accessToken = this.data.accessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData;
      this.fromAction = value
      if (value=='payin'){
        this.formData.updateId = this.data.updateData.reference_no;
        if (this.data.updateData.status == 'pending'){
          this.formData.status = 'success';
        }else{
          this.formData.status = this.data.updateData.status;
        }
        if (this.data.updateData.status == 'success'){
          this.formData.utrCode = this.data.updateData.transaction_id
        }
        this.formData.utrCode = this.data.updateData.transaction_id
        this.formData.platformTransactionId = this.data.updateData.platform_transaction_id;
        this.formData.merchantId = this.data.updateData.merchant_info.merchant_db_code;
      }else if (value=='payout'){
        this.formData.updateId = this.data.updateData.reference_no ;
        this.formData.status = this.data.updateData.status;
        this.formData.merchantId = this.data.updateData.merchant_info.merchant_db_code;
      }
      else if (value=='settlement'){
        this.formData.updateId = this.data.updateData.settlement_id ;
        this.formData.status = this.data.updateData.status;
        this.formData.merchantId = this.data.updateData.merchant_db_code;
      }
      this.formData.transactionId = this.data.updateData.transaction_id;
      this.formData.reason = (this.data.updateData.reason)==null ? '' :this.data.updateData.reason ; 
    },
    showRefund(value){
      this.$refs['modal-refund'].show()
      this.accessToken = this.data.accessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData;
      this.fromAction = value
      if (value=='payin'){
        this.formData.updateId = this.data.updateData.reference_no;
        if (this.data.updateData.status == 'success'){
          this.formData.status = 'reversed';
        }else{
          this.formData.status = this.data.updateData.status;
        }
        this.formData.platformTransactionId = this.data.updateData.platform_transaction_id;
        this.formData.merchantId = this.data.updateData.merchant_info.merchant_db_code;
      }
      if (value=='payout'){
        this.formData.updateId = this.data.updateData.reference_no;
        if (this.data.updateData.status == 'complete'){
          this.formData.status = 'reversed';
        }else{
          this.formData.status = this.data.updateData.status;
        }
        this.formData.platformTransactionId = this.data.updateData.payout_db_code;
        this.formData.merchantId = this.data.updateData.merchant_info.merchant_db_code;
      }
     
      this.formData.remarks = "测试冲正"
    },
    resetModal() {
      this.modalSubmit = false;
      this.modalLoading = false;
      this.formData={
        updateId: "",
        merchantId:"",
        password:"",
        status:"",
        reason:"",
        remarks:"",
        platformTransactionId:"",
        transactionId:"",
        utrCode:""
      }
    },
    async onChangeFile(e) {
      let file = e.target.files[0];
      this.formData.files = file
    },
    updateCallback(){
      this.modalSubmit = true
      this.$v.formData.$touch();
      if (this.fromAction=='payin' && this.formData.status=='success' && this.formData.utrCode==''){
        Swal.fire("Error", "Please enter Transaction ID/UTR No.", "error");
        return;
      }
      if (this.$v.formData.$invalid) {
        return;
      } else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("updateId", this.formData.updateId);
        bodyFormData.append("merchantId", this.formData.merchantId);
        bodyFormData.append("password", this.formData.password);
        if (this.fromAction=='payin'){
          bodyFormData.append("status", this.formData.status);
          if (this.formData.status =='failed'){
            bodyFormData.append("reason", this.formData.reason);
          }else{
            bodyFormData.append("reason","");
          }
         
          bodyFormData.append("transactionId",this.formData.platformTransactionId);
          if (this.formData.utrCode !==''){
            bodyFormData.append("UTR",this.formData.utrCode);
          }
        }
        //submit only status==failed && current failed reason is blank don't update
        var postUrl = ""
        if (this.fromAction=='payin'){
          postUrl = appConfig.APIHostAdmin + "controller/admin/triggerPayinCallback";
        }else if (this.fromAction=='payout'){
          postUrl = appConfig.APIHostAdmin + "controller/admin/triggerPayoutCallback";
        }else if (this.fromAction=='settlement'){
          postUrl = appConfig.APIHostAdmin + "controller/admin/triggerSettlementCallback";
        }
        axios({
            method: "post",
            url: postUrl,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.returnData = []
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              Swal.fire({
                icon: 'success',
                title: 'Callback Triggered Successfully',
                html: `The callback has been successfully triggered!`,
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              }).then(() => {
                this.$refs['modal-trigger-callback'].hide()
                this.$refs['modal-trigger-success'].hide()
                console.log(this.$route.name)
                if (this.$route.name=='processing payin'){
                  this.$router.push({ name: 'payin'})
                }
               
                this.$emit('callParentFunction');
              })
              
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
    refund(){
      this.modalSubmit = true
      this.$v.formData.$touch();
      if (this.$v.formData.$invalid) {
        return;
      } else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("updateId", this.formData.updateId);
        bodyFormData.append("merchantId", this.formData.merchantId);
        bodyFormData.append("password", this.formData.password);
        bodyFormData.append("status", this.formData.status);
        bodyFormData.append("transactionRemark",this.formData.remarks);
        bodyFormData.append("platformTransactionId",this.formData.platformTransactionId);
        var postUrl = ""
        if (this.fromAction=='payin'){
          postUrl = appConfig.APIHostAdmin + "controller/admin/updateReversedStatus";
        }
        if (this.fromAction=='payout'){
          postUrl = appConfig.APIHostAdmin + "controller/admin/updatePayoutReversedStatus";
        }
        axios({
            method: "post",
            url: postUrl,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.returnData = []
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              Swal.fire({
                icon: 'success',
                title: 'Transaction Reversed Successfully',
                html: `The transaction has been successfully reversed!`,
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              }).then(() => {
                this.$refs['modal-refund'].hide()
                console.log(this.$route.name)
                if (this.$route.name=='processing payin'){
                  this.$router.push({ name: 'payin'})
                }
               
                this.$emit('callParentFunction');
              })
              
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.formData.apiID = data.apiID;
          this.formData.apiHash = data.apiHash;
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },

  }
};
</script>
<style scoped>
#eye_current,
  #eye,
  #eye2,
  #eye3{
  position: absolute;
  top: 4px;
  padding: 5px 10px;
  right: 5px;
  text-align: center;
  width: 32px;
  color: #999;
  z-index: 3;
  background-color: #fff;
  cursor: pointer;
  }
</style>